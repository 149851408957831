/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import BtnA from "../images/btn_a.inline.svg"
import Headerhome from "./headerhome"
import "./layout.css"

import LogoDark from "../images/Dagpay_dark.inline.svg"

const Homelayout = ({ children }) => {

  return (
    <>
      <Headerhome/>

        <main className="homemain">{children}</main>
        <footer className="lightfooter">
          <div className="upfooter">
            <h4>What are you waiting for?</h4>
              <p>Start using Dagpay today.</p>
            <a href="https://app.dagpay.io/sign-up/" className="bluebtn">Sign up now <BtnA/></a>
          </div>
          <div className="downfooter">
            <Link className="footer-logo" to="/"><LogoDark/></Link>
              <ul>
                  <li><a href="https://app.dagpay.io/files/Dagpay_Terms_of_Services.pdf" target="_blank">Terms of Service</a></li>
                  <li><a href="https://app.dagpay.io/files/DagPay_Privacy_Policy.pdf" target="_blank">Privacy Policy</a></li>
                  <li><a href="mailto:support@dagpay.io">Contact Us</a></li>
                  <li><a href="https://dagcoin.org/" target="_blank">Dagcoin.org</a></li>
              </ul>
          </div>
        </footer>
  
    </>
  )
}

Homelayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Homelayout